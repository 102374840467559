<template>
  <div class="page page-home">
    <div class="page__wrap" data-animate="page-wrap">
      <!-- Header -->
      <PagesHomeHeader :header="home.homeHeader[0]" />

      <div data-animate="page-content">
        <!-- Highlights Content and Horizontal Scroll -->
        <LazyPagesHomeHighlightProducts
          :content="home.homeHighlightsContent[0]"
        />
        <!-- Project and Swiper -->
        <LazyPagesHomeProjects :projects="home.homeProjects" />

        <!-- Banner Azienda -->
        <LazyPagesHomeEditorialBanner
          v-if="home?.homeBanner?.[0]"
          :content="{
            ...home.homeBanner[0],
            homeBannerLink: {
              key: 'banner-company',
              route: { name: 'company' },
            },
          }"
        />
        <!-- Latest News -->
        <LazyPagesNewsLatest class="p-top-3 p-bot-3" :news="news" />

        <!-- Banner Bespoke -->
        <LazyPagesHomeEditorialBanner
          v-if="home?.homeBanner?.[1]"
          :content="{
            ...home.homeBanner[1],
            homeBannerLink: {
              key: 'banner-bespoke',
              route: { name: 'bespoke' },
            },
          }"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCraftStore } from '@/store/craft.js'

// Importa le query GraphQL
import queries from '@/graphql/craft/queries/index.js'

import {
  onBeforeEnter,
  onEnter,
  onLeave,
} from '~/assets/js/page-transitions/home.js'

definePageMeta({
  layout: 'default',
  pageTransition: {
    onBeforeEnter: (el) => {
      onBeforeEnter(el)
    },
    onEnter: async (el, done) => {
      const tl = onEnter(el, done)
      await tl.delay(0.5).play()
      done()
    },
    onLeave: async (el, done) => {
      const tl = onLeave(el)
      await tl.play()
      window.lenis.scrollTo(0, { immediate: true })
      done()
    },
  },
})

const { configuration } = useCraftStore()

// Fetch Data
const query = queries.home.getHome

const variables = computed(() => ({
  site: configuration.site,
  marketId: configuration.marketId,
}))

const { data } = await useGraphql(query, variables)

if (!data.value.homeEntries?.[0]) {
  throw createError({ statusCode: 404 })
}
const home = computed(() => data.value.homeEntries?.[0])
const news = computed(() => data.value?.newsEntries)
const seo = computed(() => data.value?.homeEntries?.[0]?.seo || {})

// Seo
useSeo({
  ...seo.value,
  meta: [
    {
      name: 'description',
      content: seo.value?.description,
    },
  ],
})
</script>
